import React, { useEffect }  from "react";
import M from "materialize-css";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import logo from "../../static/logo1.jpg";


export default function Layout({children}) {
  useEffect(() => {
    let element = document.querySelectorAll(".sidenav");
    M.Sidenav.init(element);
  }, []);
    return (
      <>
        <Helmet>
          <link
            rel="preload"
            href="https://fonts.googleapis.com/icon?family=Material+Icons&display=swap"
            as="style"
          ></link>
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons&display=swap"
            rel="stylesheet"
          ></link>
          <meta name="image" content={logo} />
        </Helmet>
        <nav className="white" role="navigation">
          <div className="nav-wrapper container">
            <a id="logo-container" href="/" className="brand-logo">
              <img
                src={logo}
                width="200px"
                height="62px"
                style={{ padding: "10px" }}
                alt="Logo"
              />
            </a>
            <ul className="right hide-on-med-and-down">
              <li>
                <a href="/">Inicio</a>
              </li>
              <li>
                <a href="/" className="disabled">
                  Área Privada
                </a>
              </li>
            </ul>
            <ul id="nav-mobile" className="sidenav">
              <li>
                <a href="/">Inicio</a>
              </li>
              <li>
                <a href="/" className="disabled">
                  Área Privada
                </a>
              </li>
            </ul>
            <a href="#" data-target="nav-mobile" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </a>
          </div>
        </nav>
        <main>{children}</main>
        <footer className="black page-footer">
          <div className="container">
            <div className="row">
              <div className="col l">
                <h5 className="white-text">Sobre Onetic</h5>
                <p className="grey-text text-lighten-4">
                  Nace ante la dificultad de ciertos sectores para encontrar
                  soluciones de alto nivel, personal cualificado y asesoramiento
                  basado en tecnologías actuales.
                </p>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="center-align container">
              Onetic Sistemas S.L.&nbsp;&copy; {new Date().getFullYear()} |{" "}
              <Link to="/avisolegal">Aviso legal</Link>
            </div>
          </div>
        </footer>
      </>
    );
};