import React, { useEffect } from "react";
import M from "materialize-css";

export default function Parallax({children,imagen,color}) {
  useEffect(() => {
    let element = document.querySelectorAll(".parallax");
    M.Parallax.init(element);
  }, []);
    return (
      <div className="parallax-container valign-wrapper">
        <div className="section no-pad-bot">
          <div className="container">
            <div className="row center">
              <h5 className={ "header s12 " + color } >{children}</h5>
            </div>
          </div>
        </div>
        <div className="parallax">
          <img src={imagen} className="flow-text" />
        </div>
      </div>
    );
};